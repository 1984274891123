<template>
  <div class="container" :style="{height: $store.state.menuFlag?'100%':'calc(100% - 70px)'}">
    <div>
      <p class="p-header">
        <span></span>
        <span>筛选条件</span>
      </p>
      <div class="header">
        <div class="div1">
          <span>项目:</span>
          <!--          <el-select v-model="value1" placeholder="请选择" @change="ProjectChange">-->
          <!--            <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item.Id">-->
          <!--            </el-option>-->
          <!--          </el-select>-->
          <Cascader :value.sync="value1" :options="Project" @MyChange="ProjectChange"
                    :multiple="false"></Cascader>
        </div>
        <div class="div1">
          <span>CRF表:</span>
          <el-select ref="multiSelect" :loading="loading" value-key="Id" v-model="value2" placeholder="请选择"
                     collapse-tags @change="CRFChange">
            <el-option v-for="(item, index) in CRFSurface" :key="index" :label="item.Name"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="div1">
          <span>随访时间:</span>
          <el-date-picker v-model="startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                          @change="startDateChange">
          </el-date-picker>
          <span style="width: 30px">至</span>
          <el-date-picker v-model="endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                          @change="endDateChange">
          </el-date-picker>
        </div>
        <div class="div1">
          <span>访视次数:</span>
          <el-input style="width: 216px" v-model="Visit" placeholder="请输入内容" @change="VisitChange"></el-input>
        </div>
      </div>
      <div style="height: calc(100vh - 270px)" @click="myFrame" class="iframeId">
        <iframe :class="{ myFrame: i == 0 ? true : false }" frameborder="0" name="myFrame" ref="myFrame"
                scrolling="yes" width="100%" height="100%" :src="Url"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import derive from '../../api/derive'
import Cascader from "@/components/Cascader.vue";

export default {
  name: 'DataBoard',
  components: {Cascader},
  data() {
    return {
      Project: [],
      value1: null,
      value2: null,
      startDate: '',
      endDate: '',
      Visit: null,
      CRFSurface: [],
      loading: false,
      Url: '',
      i: 1,
    }
  },
  methods: {
    //项目
    async ProjectList() {
      derive.ProjectListData().then((res) => {
        if (res.data.Status == 1) {
          this.Project = res.data.Data
          this.PatentProject = JSON.parse(JSON.stringify(res.data.Data))
          this.PatentProject.unshift({
            Name: '全部',
            Id: 'all'
          })
        } else {
          alert(res.data.Message)
        }
      })
    },
    //获取问卷token
    async QuestionnaireToken() {
      derive.QuestionnaireTokenData().then((res) => {
        if (res.data.Status == 1) {
          this.Url = `${res.data.Data.Url}/#/DataBoard?type=1&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}`
          // this.Url = `${window.global_config.BASE_URL1}/#/DataBoard?type=1&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}`
        } else {
          alert(res.data.Message)
        }
      })
    },
    //选中项目出问卷列表
    ProjectChange(e) {
      this.value2 = null
      this.loading = true
      const parameter = {
        ProjectId: e.slice(-1),
      }
      derive.CRFSurfaceData(parameter).then((res) => {
        if (res.data.Status == 1) {
          this.loading = false
          this.CRFSurface = res.data.Data
        } else {
          alert(res.data.Message)
        }
      });
      //组织管理员则传[]给iframe
      // if (this.RoleType != 'D') {
      //   this.PatientGetIdListData();
      // }
    },
    //问卷关闭下拉回调
    // visibleCRFChange(e) {
    //     if (e == false) {
    //         this.$refs.myFrame.contentWindow.postMessage(this.list, '*')
    //         this.i = 1
    //     } else {
    //         this.i = 0
    //     }
    // },
    //选中问卷列表
    CRFChange(e) {
      this.list = e
      let obj = {
        type: 2,
        questionnaire: this.list
      }
      this.$refs.myFrame.contentWindow.postMessage(obj, '*')
      // if (e == false) {
      //     this.$refs.myFrame.contentWindow.postMessage(obj, '*')
      //     this.i = 1
      // } else {
      //     this.i = 0
      // }
    },
    //开始时间
    startDateChange(e) {
      let objGroup = {
        type: 3,
        num: e
      }
      this.$refs.myFrame.contentWindow.postMessage(objGroup, '*')
    },
    //结束时间
    endDateChange(e) {
      let objfollowUp = {
        type: 4,
        num: e
      }
      this.$refs.myFrame.contentWindow.postMessage(objfollowUp, '*')
    },
    RemoveTag(tag) {
      tag['type'] = 5
      this.$refs.myFrame.contentWindow.postMessage(tag, '*')
    },
    //访视次数回调
    VisitChange(e) {
      let sty = /[^0-9]/g
      if (sty.test(e)) {
        this.Visit = ''
        this.$message.error('请输入数字')
      } else {
        let objVisit = {
          type: 1,
          num: e
        }
        this.$refs.myFrame.contentWindow.postMessage(objVisit, '*')
      }
    },
    myFrame() {
      this.$refs.multiSelect.blur()
      this.i = 1
    },
  },
  created() {
    this.ProjectList()
    this.QuestionnaireToken()
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  box-sizing: border-box !important;
  width: 100%;

  & > div {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box !important;
  }
}

.p-header {
  overflow: hidden;
  margin-bottom: 10px;

  span:nth-child(1) {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #3388ff;
    margin-right: 10px;
    vertical-align: middle;
  }

  span:nth-child(2) {
    display: inline-block;
    font-weight: 500;
    color: #595d67;
    // @include add-size($font_size_16);
    vertical-align: middle;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;

  // align-items: center;
  .div1 {
    margin-bottom: 10px;
  }

  span {
    display: inline-block;
    width: 70px;
    // margin-right: 10px;
    margin-left: 10px;
  }
}

.myFrame {
  pointer-events: none;
}
</style>
